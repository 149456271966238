import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const ItemPictureMap = {
  content: {
    bcKey: 'Content',
    type: 'string',
    required: false,
  },
  mediaId: {
    bcKey: 'Media_Id',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type ItemPicture = ODataObjectMap<typeof ItemPictureMap>;

const ItemPictureSchema = `
  type ItemPicture {
    content: String
    mediaId: String
  }

  extend type Query {
    itemPicture(company: String!, itemNo: String!): PaginatedItemPicture
  }

  type PaginatedItemPicture {
    items: [ItemPicture!]!
  }
`;

const ItemPictureQuery = `
  query ItemPicture($company: String!, $itemNo: String!) {
    itemPicture(company: $company, itemNo: $itemNo) {
      items {
        content
        mediaId
      }
    }
  }
`;

export { ItemPictureSchema, type ItemPicture, ItemPictureQuery, ItemPictureMap }; 